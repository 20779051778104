@import '../../styles/variables';

.link {
  text-decoration: none;
  color: $white;
  @include menu;

  @media screen and (max-width: 64em) {
    padding: 1rem;
  }

  @media screen and (max-width: $mobile-small-screen) {
    padding: 0.5rem;
  }
}
