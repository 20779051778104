@import '../../../styles/variables';

.footer {
  background-color: $light-1;

  .wrapper {
    max-width: $max-width;
    margin: 0 auto;
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
  }

  .container-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    @media screen and (max-width: $tablet-large-screen) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
    }
  }

  .join {
    display: flex;
    flex-direction: column;
  }

  .subheader {
    color: $gray-10;
    @include caption-m;
    margin-bottom: 1.5rem;
  }

  .container-buttons {
    display: flex;
    gap: 0.5rem;

    @media screen and (max-width: $mobile-extra-small-screen) {
      flex-direction: column;
    }
  }

  .button-primary {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border: none;
    color: $white;
    padding: 0.5rem 1.5rem;
    background-color: $orange-2;
    border-radius: $border-radius;
    white-space: nowrap;
    @include button-s;

    &:hover {
      background-color: $orange-7;
    }

    @media screen and (max-width: $mobile-small-screen) {
      padding: 0.5rem;
      @include caption-s;
    }
  }

  .button-secondary {
    color: $white;
    background-color: $gray-8;
    border: 1px solid $gray-4;
    padding: 0.5rem 1.5rem;
    border-radius: $border-radius;
    @include button-s;

    &:hover {
      background-color: $gray-7;
      border: 1px solid $gray-9;
    }

    @media screen and (max-width: $mobile-small-screen) {
      padding: 0.5rem;
      @include caption-s;
    }
  }

  .container-links {
    display: flex;
    gap: 12rem;

    @media screen and (max-width: $tablet-large-screen) {
      gap: 2rem;
    }

    @media screen and (max-width: $mobile-medium-screen) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 4rem;
    }
  }

  .nerdbord {
    display: flex;
    flex-direction: column;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .documents {
    grid-column: 1 / 2;
    grid-row: 2 / 2;
  }

  .contact {
    grid-column: 2 / 3;
    grid-row: 2 / -1;
  }

  .links {
    display: flex;
    flex-direction: column;
    list-style: none;
  }

  a {
    color: black;
    padding: 0.5rem 0;
  }

  .discord {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }

  .container-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $mobile-medium-screen) {
      flex-direction: column-reverse;
      gap: 1rem;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .copyright {
    color: $gray-10;
    @include subheader-4;
  }

  .socials {
    display: flex;
    flex-direction: column;

    .link {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .name {
      @include menu;
      font-weight: 600;
    }
  }
}
