@import '../../styles/variables';

.header {
  background-color: $gray-7;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-bottom: solid 1px $gray-1;
  position: sticky;
  width: 100%;
  z-index: 9;
  top: 0px;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  gap: 2rem;

  @media screen and (max-width: $tablet-large-screen) {
    display: none;
  }

  .left-container {
    display: flex;
    align-items: center;
    gap: 3.5rem;
  }

  .links {
    a {
      padding: 0 0.6rem;
      &:hover {
        background-color: $gray-8;
        padding: 0.6rem;
        border-radius: $border-radius;

        @media screen and (max-width: $tablet-large-screen) {
          padding: 1rem;
        }
      }
    }
  }
}

.right-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  @include menu;

  .inside {
    padding-left: 5px;
    @include button-s;
  }

  a {
    &:hover {
      background-color: $gray-8;
      padding: 0.6rem;
      border-radius: $border-radius;

      @media screen and (max-width: $tablet-large-screen) {
        padding: 1rem;
      }
    }
  }
}

.mobile-nav {
  display: none;

  @media screen and (max-width: $tablet-large-screen) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0 1rem;
  }

  @media screen and (max-width: $mobile-small-screen) {
    padding: 1.5rem 0 1rem;
  }

  .logo {
    @media screen and (max-width: $mobile-medium-screen) {
      width: 120px;
      height: auto;
    }

    @media screen and (max-width: $mobile-small-screen) {
      width: 80px;
      height: auto;
    }
  }

  .right-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    @media screen and (max-width: $mobile-medium-screen) {
      padding-right: 0.5rem;
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: center;

      border: none;
      background-color: rgba(34, 34, 37, 1);
      border-radius: $border-radius;
      padding: 1rem;
      cursor: pointer;

      height: 38px;
      width: 38px;

      &:focus {
        outline: none;
      }

      @media screen and (max-width: $mobile-medium-screen) {
        padding: 0.5rem;
      }

      @media screen and (max-width: $mobile-small-screen) {
        height: 38px;
        width: 38px;
      }
    }

    .icon {
      width: 10px;

      &:focus {
        outline: none;
      }

      &,
      &::before,
      &::after {
        display: block;
        height: 1.6px;
        background-color: white;
        transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
        content: '';
      }

      &::before {
        width: 10px;

        transform: translateY(calc(-3.5px - 50%));
      }

      &::after {
        width: 10px;

        transform: translateY(calc(3.5px - 50%));
      }

      &-active {
        height: 0;

        &::before {
          width: 10px;
          transform: translateY(0) rotate(135deg);
        }

        &::after {
          width: 10px;
          transform: translateY(-1px) rotate(-135deg) translateX(1px);
        }
      }
    }
  }
}

.sidebar {
  position: absolute;
  overflow-x: hidden;
  left: 0;
  top: 4.5rem;
  width: 100%;
  height: 100vh;
  background-color: $gray-7;
  padding: 0.75rem;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;

  @media screen and (max-width: $mobile-small-screen) {
    top: 4.5rem;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    max-width: 13.45rem;

    .links {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;
      gap: 0.5rem;

      @media screen and (max-width: $mobile-small-screen) {
        gap: 1rem;
      }

      a {
        @include header-5;

        &:hover {
          background-color: $gray-8;
          padding: 0.6rem;
          border-radius: $border-radius;

          @media screen and (max-width: 64em) {
            padding: 1rem;
          }
          @media screen and (max-width: $mobile-small-screen) {
            padding: 0.5rem;
          }
        }
      }
    }

    .socials {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        @include header-5;
      }
    }
  }

  &-active {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
}
